import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Grid } from '@mui/material'
import { isEqual } from 'lodash'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import SpecialtyField from 'components/UserFields/SpecialtyField'
import SubspecialtyField from 'components/UserFields/SubspecialtyField'
import NPINumberField from 'components/UserFields/NPINumberField'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import {
  getCandidateTypeOptions,
  getBoardingEligibilityOptions,
  getStatesOptions,
  getYearsExperienceOptions,
} from 'components/UserFields/helpers'
import { professionFields } from 'components/UserFields/professionDefinitions.js'

const ProfessionalDetailsSection = ({ formData, onSave, candidateTypes = [] }) => {
  const [localFormData, setLocalFormData] = useState(formData)
  const [initialData, setInitialData] = useState(formData)
  const [candidateType, setCandidateType] = useState({})
  const [errors, setErrors] = useState({})
  const [hasChanges, setHasChanges] = useState(false)

  // Set initial data and candidate type on mount or formData update
  useEffect(() => {
    setLocalFormData(formData)
    setInitialData(formData)
    setHasChanges(false)
  }, [formData])

  useEffect(() => {
    if (localFormData.candidateTypeId) {
      const typeData = candidateTypes.find(type => type.id === localFormData.candidateTypeId)
      setCandidateType(professionFields.find(field => field.name === typeData?.name) || {})
    }
  }, [formData.candidateTypeId, localFormData.candidateTypeId, candidateTypes])

  // Clear specific field errors when user inputs values
  const clearError = fieldName => {
    if (errors[fieldName]) {
      setErrors(prevErrors => {
        // eslint-disable-next-line no-unused-vars
        const { [fieldName]: _, ...updatedErrors } = prevErrors
        return updatedErrors
      })
    }
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    clearError(name)
    const updatedFormData = {
      ...localFormData,
      [name]: value,
      ...(name === 'candidateTypeId' && { specialtyIds: [], subspecialtyIds: [] }),
      ...(name === 'specialtyIds' && { subspecialtyIds: [] }),
    }
    setLocalFormData(updatedFormData)
    setHasChanges(!isEqual(updatedFormData, initialData))
  }

  const handleComplexChange = (name, valueOrEvent) => {
    const value = Array.isArray(valueOrEvent) ? valueOrEvent : valueOrEvent.target?.value
    clearError(name)
    const updatedFormData = { ...localFormData, [name]: value }
    setLocalFormData(updatedFormData)
    setHasChanges(!isEqual(updatedFormData, initialData))
  }

  const validateFields = useCallback(() => {
    const newErrors = {}
    if (!localFormData.candidateTypeId) newErrors.candidateTypeId = 'Profession is required'
    if (candidateType?.specialtyIds?.required && !localFormData.specialtyIds?.length) {
      newErrors.specialtyIds = 'Specialty is required'
    }
    if (candidateType?.npiNumber?.required && !localFormData.npiNumber) {
      newErrors.npiNumber = 'NPI Number is required'
    }
    if (candidateType?.boardEligibility?.required && !localFormData.boardEligibility) {
      newErrors.boardEligibility = 'Board Eligibility is required'
    }
    if (!localFormData.yearsExperience)
      newErrors.yearsExperience = 'Years of Experience is required'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }, [localFormData, candidateType])

  const handleSave = () => {
    if (!validateFields()) return

    if (hasChanges) {
      const changedFields = Object.keys(localFormData).reduce((acc, key) => {
        if (!isEqual(localFormData[key], initialData[key])) {
          acc[key] = localFormData[key]
        }
        return acc
      }, {})

      if (Array.isArray(changedFields.specialtyIds)) {
        changedFields.specialtyIds = changedFields.specialtyIds.map(specialty =>
          parseInt(specialty.id, 10),
        )
      }
      if (Array.isArray(changedFields.subspecialtyIds)) {
        changedFields.subspecialtyIds = changedFields.subspecialtyIds.map(subspecialty =>
          parseInt(subspecialty.id, 10),
        )
      }

      onSave({ ...changedFields, id: formData.id })
      setInitialData(localFormData)
      setHasChanges(false)
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GenericSelectField
            value={localFormData.candidateTypeId}
            label='Profession'
            name='candidateTypeId'
            handleChange={handleInputChange}
            options={getCandidateTypeOptions({ candidateTypes }) || []}
            required
            error={errors.candidateTypeId}
            helperText={errors.candidateTypeId}
          />
        </Grid>
        <Grid item xs={12}>
          <SpecialtyField
            value={localFormData.specialtyIds || []}
            handleChange={value => handleComplexChange('specialtyIds', value)}
            candidateTypeId={localFormData.candidateTypeId}
            setErrors={setErrors}
            errors={errors}
            required={candidateType?.specialtyIds?.required}
            name='specialtyIds'
            label='Specialty'
          />
        </Grid>
        <Grid item xs={12}>
          <SubspecialtyField
            value={localFormData.subspecialtyIds || []}
            handleChange={value => handleComplexChange('subspecialtyIds', value)}
            name='subspecialtyIds'
            specialtyIds={localFormData.specialtyIds}
            label='Subspecialty'
          />
        </Grid>
        {candidateType?.npiNumber?.show !== false && (
          <Grid item xs={12} md={6}>
            <NPINumberField
              handleChange={handleInputChange}
              value={localFormData.npiNumber}
              label='NPI Number'
              name='npiNumber'
              setErrors={setErrors}
              errors={errors}
              required={candidateType?.npiNumber?.required}
            />
          </Grid>
        )}
        {candidateType?.boardEligibility?.show !== false && (
          <Grid item xs={12} md={6}>
            <GenericSelectField
              value={localFormData.boardEligibility}
              label='Board Eligibility'
              name='boardEligibility'
              handleChange={handleInputChange}
              options={getBoardingEligibilityOptions()}
              required={candidateType?.boardEligibility?.required}
              error={errors.boardEligibility}
              helperText={errors.boardEligibility}
            />
          </Grid>
        )}
        {candidateType?.licensedStates?.show !== false && (
          <Grid item xs={12}>
            <GenericMultipleSelect
              value={localFormData.licensedStates}
              label='Licensed States'
              name='licensedStates'
              handleChange={value => handleComplexChange('licensedStates', value)}
              options={getStatesOptions()}
              required={candidateType?.licensedStates?.required}
              error={errors.licensedStates}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <GenericRadioGroup
            value={localFormData.yearsExperience}
            label='Years of Experience'
            name='yearsExperience'
            handleChange={handleInputChange}
            options={getYearsExperienceOptions()}
            required
            error={errors.yearsExperience}
          />
        </Grid>
      </Grid>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges || Object.keys(errors).length > 0}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Box>
  )
}

export default ProfessionalDetailsSection
