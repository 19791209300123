import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Autocomplete, Chip, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Check } from '@mui/icons-material'

const SpecialtyField = ({
  name,
  label,
  setErrors,
  errors,
  value = [],
  handleChange,
  disabled,
  candidateTypeId,
  required = false, // Add a required prop to indicate if the field is mandatory
  ...props
}) => {
  const theme = useTheme()
  const { specialties = [], candidateTypes = [] } = useSelector(state => state.supportingData)
  const [specialtyOptions, setSpecialtyOptions] = useState([])

  useEffect(() => {
    const candidateType = candidateTypes.find(x => x.id === candidateTypeId)
    const newSpecialtyOptions = specialties
      .filter(x => candidateType?.specialtyIds?.includes(x.id))
      .map(x => ({ id: x.id, name: x.name }))
    setSpecialtyOptions(newSpecialtyOptions || [])
  }, [candidateTypeId, candidateTypes, specialties])

  if (specialtyOptions.length === 0) return null // Hide if no options

  const selectedValues = Array.isArray(value) ? value : []

  const handleMultiSelectChange = (event, newValue) => {
    if (newValue.length === 0 && required) {
      setErrors({ ...errors, specialtyIds: 'Specialty is required' })
    } else {
      const { ...remainingErrors } = errors
      setErrors(remainingErrors) // Clear the error for specialtyIds if selections are made
    }

    handleChange({
      target: { name, value: newValue },
    })
  }

  return (
    <Autocomplete
      multiple
      id={name}
      options={specialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleMultiSelectChange}
      value={selectedValues}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            label={option.name}
            {...getTagProps({ index })}
            sx={{
              '& .MuiChip-label': {
                color: theme.palette.primary.dark,
              },
              '&.MuiChip-root.Mui-disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
            }}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {selected && <Check sx={{ marginRight: 1, color: 'green' }} />}
          {option.name}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          error={Boolean(errors.specialtyIds)}
          helperText={errors.specialtyIds}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={required ? `${label} *` : label} // Append asterisk if required
        />
      )}
      {...props}
    />
  )
}

export default SpecialtyField
