import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, FormControlLabel, Checkbox } from '@mui/material'
import { isEqual } from 'lodash'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import GenericMultiSelectCheckboxField from 'components/UserFields/GenericMultiSelectCheckboxField' // Import the new component
import { getStatesOptions } from 'components/UserFields/helpers'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import GenericChipField from 'components/UserFields/GenericChipField'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import {
  getPracticeTypeOptions,
  AREA_ICONS,
  getYesNoOptions,
  getAreaOptions,
  getAcademicOptions,
} from 'components/UserFields/helpers'
import PreferencesImage from 'static/images/preferences-image.png'
import { PRACTICE_TYPE_HELPER_TEXT, ACADEMIC_TYPE_HELPER_TEXT } from 'data/fieldHelperText'

const PreferencesStep = ({ formData, setFormData, onNext, onBack, candidate, user, step }) => {
  const [errors, setErrors] = useState({})
  const [hasLocationPreference, setHasLocationPreference] = useState(false)
  const [initialFormData, setInitialFormData] = useState({})

  const stateOptions = getStatesOptions()

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        preferredStates: candidate.preferredStates || [],
        areaTypePreference: candidate.areaTypePreference || '',
        preferredPracticeTypes: candidate.preferredPracticeTypes || [], // Initialize practice type
        relocationPreference: candidate.relocationPreference === true ? 'yes' : 'no',
        aboutJob: candidate.aboutJob || '',
        preferredAreaTypes: candidate.preferredAreaTypes || [],
        academicPreference: candidate.academicPreference,
      }
      setFormData(initialData)
      setInitialFormData(initialData)
      setHasLocationPreference(candidate.preferredStates.length > 0)
    }
  }, [candidate, setFormData])

  const handleInputChange = event => {
    const { name, value, type, checked } = event.target
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleLocationPreferenceChange = event => {
    setHasLocationPreference(event.target.checked)
    if (!event.target.checked) {
      setFormData({
        preferredStates: formData.preferredStates,
      })
    }
  }

  const handleValidation = () => {
    const newErrors = {}
    if (!formData.relocationPreference) newErrors.relocationPreference = 'This field is required'
    if (!formData.aboutJob) newErrors.aboutJob = 'This field is required'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }
  const handleSubmit = () => {
    if (handleValidation()) {
      const dataToSubmit = {
        id: user.candidateId,
        aboutJob: formData.aboutJob,
        relocationPreference: formData.relocationPreference === 'yes',
        preferredPracticeTypes: formData.preferredPracticeTypes || [],
        preferredAreaTypes: formData.preferredAreaTypes || [],
        academicPreference: formData.academicPreference,
        preferredStates: hasLocationPreference ? formData.preferredStates : [],
      }
      const submitForm = !isEqual(formData, initialFormData)
      onNext({ dataToSubmit, step, submitForm: submitForm })
    }
  }
  return (
    <Box padding={2} maxWidth={600} margin='auto'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            component='img'
            src={PreferencesImage}
            alt='Preferences'
            sx={{
              width: '40%',
              height: 'auto',
              marginBottom: 1,
              display: 'block',
              mx: 'auto',
            }}
          />
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            {/* Location Preference */}
            <Box mb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasLocationPreference}
                    onChange={handleLocationPreferenceChange}
                  />
                }
                label='I have a location preference'
              />
              {hasLocationPreference && (
                <GenericMultipleSelect
                  label='Preferred States'
                  name='preferredState'
                  value={formData.preferredStates || []}
                  handleChange={e => setFormData({ ...formData, preferredStates: e.target.value })}
                  options={stateOptions}
                />
              )}
            </Box>
            <Box mb={3}>
              <GenericRadioGroup
                value={formData.relocationPreference}
                label='Willing to Relocate'
                name='relocationPreference'
                handleChange={handleInputChange}
                options={getYesNoOptions()}
                required={true}
                error={errors.relocationPreference}
              />
            </Box>
            {/* Area Type Preference */}
            <Box mb={3}>
              <GenericChipField
                value={formData.preferredAreaTypes}
                label='Area Type Preference'
                name='preferredAreaTypes'
                icons={AREA_ICONS}
                options={getAreaOptions()}
                handleChange={handleInputChange}
              />
            </Box>

            {/* Practice Type Preference */}
            <Box mb={3}>
              <GenericMultiSelectCheckboxField
                label='Practice Type Preference'
                name='preferredPracticeTypes'
                value={formData.preferredPracticeTypes || []}
                options={getPracticeTypeOptions()}
                handleChange={handleInputChange}
                toolTipText={PRACTICE_TYPE_HELPER_TEXT}
              />
            </Box>

            {/* Academic Preference */}
            <Box mb={3}>
              <GenericRadioGroup
                value={formData.academicPreference}
                toolTipText={ACADEMIC_TYPE_HELPER_TEXT}
                label='Preferred Academic Setting'
                name='academicPreference'
                handleChange={handleInputChange}
                options={getAcademicOptions()}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Grid item xxs={12}>
              <GenericMultiLineTextField
                name='aboutJob'
                label='Describe your ideal job'
                placeholder='Describe the ideal setting, work environment, and team that would allow you to thrive professionally.'
                value={formData.aboutJob}
                required={true}
                error={errors.aboutJob}
                helperText='This field is required'
                handleChange={handleInputChange}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Navigation Buttons */}
      <Grid container spacing={2} justifyContent='center' mt={3} mb={7}>
        <Grid item>
          <Button
            onClick={onBack}
            variant='outlined'
            color='primary'
            fullWidth
            sx={{ minWidth: 120 }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            fullWidth
            sx={{ minWidth: 120 }}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PreferencesStep
