import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Grid, Typography, Paper, List, ListItemButton, ListItemText } from '@mui/material'
import BasicInformationSection from './Sections/BasicInformationSection'
import ProfessionalDetailsSection from './Sections/ProfessionalDetailsSection'
import BackgroundInformationSection from './Sections/BackgroundInformationSection'
import PreferencesSection from './Sections/PreferencesSection'
import FileSection from './Sections/FileSection'
import PasswordSection from './Sections/PasswordSection'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import { fetchRegistrationData } from 'src/redux/supportingData'
import Loader from 'components/Loader'

const CandidateProfile = () => {
  const user = useSelector(state => state.identity)
  const candidate = useSelector(state => state.candidate)
  const candidateTypes = useSelector(state => state.supportingData.candidateTypes)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(true)

  // Section anchor data
  const sections = [
    { label: '📁 File Uploads', id: 'file-uploads' },
    { label: '👤 Basic Information', id: 'basic-info' },
    { label: '💼 Professional Details', id: 'professional-details' },
    { label: '📄 Background Information', id: 'background-info' },
    { label: '❤️ Preferences', id: 'preferences' },
    { label: '🔑️ Password Reset', id: 'password' },
  ]

  useEffect(() => {
    dispatch(fetchRegistrationData())
  }, [])

  useEffect(() => {
    if (!candidate.loading) {
      setFormData({
        id: user.candidateId,
        phone: candidate.phone,
        city: candidate.city,
        state: candidate.state,
        showPhone: candidate.showPhone,
        showEmail: candidate.showEmail,
        email: candidate.email,
        searchStatus: candidate.searchStatus,
        availabilityDate: candidate.availabilityDate,
        resume: candidate.resume,
        residency: candidate.residency,
        residencyEnd: candidate.residencyEnd,
        about: candidate.about,
        aboutRaw: candidate.aboutRaw,
        visaId: candidate.visaId,
        candidateLanguages: candidate.candidateLanguages || [],
        specialtyIds: candidate.specialties,
        subspecialtyIds: candidate.subspecialties,
        npiNumber: candidate.npiNumber,
        boardEligibility: candidate.boardEligibility,
        licensedStates: candidate.licensedStates,
        candidateTypeId: candidate.candidateType?.id,
        yearsExperience: candidate.yearsExperience,
        preferredStates: candidate.preferredStates || [],
        areaTypePreference: candidate.areaTypePreference || '',
        preferredPracticeTypes: candidate.preferredPracticeTypes || [], // Initialize practice type
        relocationPreference: candidate.relocationPreference || false,
        aboutJob: candidate.aboutJob || '',
        preferredAreaTypes: candidate.preferredAreaTypes || [],
        academicPreference: candidate.academicPreference,
      })
      setLoading(false)
    }
  }, [candidate])

  const handleSaveSection = dataToSubmit => {
    dispatch(fetchUpdateCandidate(dataToSubmit, 'Profile Updated!'))
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: 'linear-gradient(to right, #e0f7fa, #ffffff)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 0',
      }}
    >
      <Grid container spacing={2} sx={{ maxWidth: '1200px', width: '100%' }}>
        {/* Sidebar */}
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              position: 'sticky',
              top: 20,
              backgroundColor: 'primary.light',
              padding: 2,
              borderRadius: 2,
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 2, textAlign: 'center' }}>
              Profile Sections
            </Typography>
            <List>
              {sections.map(section => (
                <ListItemButton
                  key={section.id}
                  href={`#${section.id}`}
                  sx={{
                    borderRadius: 1,
                    mb: 1,
                    color: 'text.primary',
                    '&:hover': {
                      backgroundColor: 'secondary.light',
                      color: 'secondary.dark',
                    },
                  }}
                >
                  <ListItemText primary={section.label} />
                </ListItemButton>
              ))}
            </List>
          </Box>
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              padding: '40px 20px',
              background: '#ffffff',
              borderRadius: '10px',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography
              variant='h4'
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: 'primary.dark',
                textAlign: 'center',
                mb: 2,
                display: 'inline-block',
                paddingBottom: '4px',
              }}
            >
              {candidate.firstName}'s Profile
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} id='file-uploads'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'info.dark',
                  }}
                >
                  <Typography variant='h6' sx={{ color: 'info.dark', fontWeight: '600', mb: 2 }}>
                    📁 File Uploads
                  </Typography>
                  <FileSection
                    formData={formData}
                    setFormData={setFormData}
                    onSave={handleSaveSection}
                    candidate={candidate}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} id='basic-info'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'secondary.main',
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{ color: 'secondary.main', fontWeight: '600', mb: 2 }}
                  >
                    👤 Basic Information
                  </Typography>
                  <BasicInformationSection
                    formData={formData}
                    setFormData={setFormData}
                    onSave={handleSaveSection}
                    candidate={candidate}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} id='professional-details'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'primary.main',
                  }}
                >
                  <Typography variant='h6' sx={{ color: 'primary.main', fontWeight: '600', mb: 2 }}>
                    💼 Professional Details
                  </Typography>
                  <ProfessionalDetailsSection
                    formData={formData}
                    setFormData={setFormData}
                    onSave={handleSaveSection}
                    candidateTypes={candidateTypes}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} id='background-info'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'success.main',
                  }}
                >
                  <Typography variant='h6' sx={{ color: 'success.main', fontWeight: '600', mb: 2 }}>
                    📄 Background Information
                  </Typography>
                  <BackgroundInformationSection
                    formData={formData}
                    setFormData={setFormData}
                    onSave={handleSaveSection}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} id='preferences'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'info.main',
                  }}
                >
                  <Typography variant='h6' sx={{ color: 'info.main', fontWeight: '600', mb: 2 }}>
                    ❤️ Preferences
                  </Typography>
                  <PreferencesSection
                    formData={formData}
                    setFormData={setFormData}
                    onSave={handleSaveSection}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} id='password'>
                <Paper
                  elevation={3}
                  sx={{
                    padding: '25px',
                    borderRadius: '12px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    borderLeft: '5px solid',
                    borderColor: 'warning.main',
                  }}
                >
                  <Typography variant='h6' sx={{ color: 'warning.main', fontWeight: '600', mb: 2 }}>
                    🔑️ Password Reset
                  </Typography>
                  <PasswordSection />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CandidateProfile
