// helpers/formHelpers.js
import { isEqual } from 'lodash'

export const getChangedFields = (localData, initialData) => {
  return Object.keys(localData).reduce((acc, key) => {
    if (!isEqual(localData[key], initialData[key])) {
      acc[key] = localData[key]
    }
    return acc
  }, {})
}

// helpers/formHelpers.js
export const clearFieldError = (fieldName, errors, setErrors) => {
  if (errors[fieldName]) {
    setErrors(prevErrors => {
      // eslint-disable-next-line no-unused-vars
      const { [fieldName]: _, ...updatedErrors } = prevErrors
      return updatedErrors
    })
  }
}

// helpers/formHelpers.js
export const validateFields = (data, requiredFields = {}) => {
  const errors = {}
  Object.keys(requiredFields).forEach(field => {
    if (requiredFields[field] && !data[field]) {
      errors[field] = `${field} is required`
    }
  })
  return errors
}

// helpers/formHelpers.js
export const handleInputChangeHelper = (event, setFormData, clearError) => {
  const { name, value, type, checked } = event.target
  clearError && clearError(name)
  setFormData(prev => ({ ...prev, [name]: type === 'checkbox' ? checked : value }))
}
