import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Typography, FormHelperText, Box } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const TextEditor = ({
  initialValue,
  isReadOnly,
  onChange,
  placeholder,
  required,
  error,
  helperText,
}) => {
  const editorRef = useRef(null) // To store editor instance
  const isMounted = useRef(false) // Track if component is mounted

  useEffect(() => {
    // Set the component as mounted
    isMounted.current = true

    return () => {
      // Cleanup on unmount
      isMounted.current = false
      if (editorRef.current) {
        editorRef.current
          .destroy()
          .then(() => {
            editorRef.current = null // Reset ref after destruction
          })
          .catch(error => {
            console.error('Error during CKEditor cleanup:', error)
          })
      }
    }
  }, [])

  const handleEditorReady = editor => {
    if (!isMounted.current) return // Prevent setting up if component is unmounted
    editorRef.current = editor // Assign editor instance to ref

    editor.setData(initialValue) // Set initial data
    if (isReadOnly) {
      editor.ui.view.editable.element.style.backgroundColor = 'transparent'
      editor.ui.view.editable.element.style.borderColor = 'transparent'
      editor.ui.view.toolbar.element.style.display = 'none'
    }
  }

  return (
    <Box>
      {placeholder && (
        <Typography
          variant='caption1'
          component='label'
          color='primary.main'
          sx={{ display: 'block', mb: 1 }}
        >
          {placeholder}
          {required && <span style={{ color: 'red' }}> *</span>}
        </Typography>
      )}

      <CKEditor
        editor={ClassicEditor}
        data={initialValue} // Pass initial data only at first load
        config={{
          placeholder: placeholder,
          toolbar: {
            shouldNotGroupWhenFull: true,
          },
        }}
        onReady={handleEditorReady} // Set initial data only when editor is ready
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange({
            about: data,
            aboutRaw: data,
          })
        }}
        disabled={isReadOnly}
      />

      {error && helperText && (
        <FormHelperText error sx={{ mt: 1 }}>
          {helperText}
        </FormHelperText>
      )}
    </Box>
  )
}

TextEditor.defaultProps = {
  isReadOnly: false,
  onChange: () => {},
  value: '',
}

TextEditor.propTypes = {
  isReadOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
}
