import React from 'react'
import { BOARD_ELIGIBILITY_ENUM, SEARCH_STATUS_ENUM } from 'data/enums'
import { splitCamelCaseToString } from 'data/helpers'
import { States, Cities } from 'data/constants'
import {
  VISA_ENUM,
  YEARS_EXPERIENCE_ENUM,
  LANGUAGE_ENUM,
  PROFICIENCY_ENUM,
  ACADEMIC_ENUM,
  AREA_ENUM,
  PRACTICE_TYPE_ENUM,
} from 'data/enums'
import { LocationCity, Home, Terrain, NotListedLocation } from '@mui/icons-material'

export const getBoardingEligibilityOptions = () => {
  const eligibilityOptions = Object.keys(BOARD_ELIGIBILITY_ENUM).map(key => ({
    id: BOARD_ELIGIBILITY_ENUM[key],
    label: splitCamelCaseToString(BOARD_ELIGIBILITY_ENUM[key]),
  }))
  return eligibilityOptions
}

export const getRequiresSponsorshipOptions = () => {
  const requiresSponsorship = [
    { id: 'No', label: 'No' },
    { id: 'Yes', label: 'Yes' },
  ]
  return requiresSponsorship
}

export const getStatesOptions = () => {
  return Object.keys(States).map(option => States[option])
}

export const getCitiesOptions = () => {
  return Object.keys(Cities).map(option => Cities[option])
}

export const getAvailabilityOptions = () => {
  const availabilityOptions = Object.keys(SEARCH_STATUS_ENUM).map(key => ({
    id: SEARCH_STATUS_ENUM[key],
    name: splitCamelCaseToString(SEARCH_STATUS_ENUM[key]),
  }))
  return availabilityOptions
}

export const getVisaOptions = () => {
  const visaOptions = Object.keys(VISA_ENUM).map((key, index) => ({
    id: index + 1,
    name: VISA_ENUM[key], // Use 'label' to match what GenericSelectField expects
  }))
  return visaOptions
}

export const getCandidateTypeOptions = ({ candidateTypes }) => {
  const options = candidateTypes.map(candidateType => ({
    id: candidateType.id,
    label: candidateType.name,
  }))
  return options
}

export const getYearsExperienceOptions = () => {
  const yearsExperienceOptions = Object.entries(YEARS_EXPERIENCE_ENUM).map(([id, name]) => ({
    id,
    name,
  }))
  return yearsExperienceOptions
}

export const getLanguageOptions = () => {
  const languageOptions = Object.entries(LANGUAGE_ENUM).map(([label, id]) => ({ id, label }))
  return languageOptions
}

export const getProficiencyOptions = () => {
  const proficiencyOptions = Object.entries(PROFICIENCY_ENUM).map(([id, name]) => ({
    id,
    label: name,
  }))
  return proficiencyOptions
}

export const getAreaOptions = () => {
  const areaOptions = Object.entries(AREA_ENUM).map(([id, name]) => ({ id, name }))
  return areaOptions
}

export const getAcademicOptions = () => {
  const academicOptions = Object.entries(ACADEMIC_ENUM).map(([id, name]) => ({ id, name }))
  return academicOptions
}

export const getYesNoOptions = () => {
  const yesNoOptions = [
    { id: 'yes', name: 'Yes' },
    { id: 'no', name: 'No' },
  ]
  return yesNoOptions
}

export const getPracticeTypeOptions = () => {
  const practiceTypeOptions = Object.entries(PRACTICE_TYPE_ENUM).map(([id, name]) => ({ id, name }))
  return practiceTypeOptions
}

export const AREA_ICONS = {
  Urban: <LocationCity />,
  Suburban: <Home />,
  Rural: <Terrain />,
  NoPreference: <NotListedLocation />,
}
