import React from 'react'
import { TextField, useTheme } from '@mui/material'

const NPINumberField = ({
  value,
  disabled,
  handleChange,
  label,
  setErrors,
  errors,
  name,
  required,
  ...props
}) => {
  const theme = useTheme()

  const validate = event => {
    const { value } = event.target
    if (value) {
      if (value.trim().length !== 10) {
        setErrors({ ...errors, npiNumber: 'NPI number must be 10 digits' })
      } else {
        delete errors.npiNumber
      }
    } else {
      delete errors.npiNumber
    }
    handleChange(event)
  }

  return (
    <TextField
      id={name}
      type='number'
      name={name}
      label={label}
      error={errors.npiNumber !== undefined}
      disabled={disabled}
      required={required}
      helperText={errors.npiNumber}
      size='small'
      onChange={event => validate(event)}
      value={value || ''}
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: theme.palette.primary.dark,
        },
        '& fieldset': disabled && { border: 'none' },
        // Remove spin buttons in Chrome, Safari, Edge
        '& input[type=number]': {
          MozAppearance: 'textfield', // Firefox
          WebkitAppearance: 'none', // Chrome, Safari, Edge
          appearance: 'textfield',
          '&::-webkit-outer-spin-button': {
            display: 'none',
          },
          '&::-webkit-inner-spin-button': {
            display: 'none',
          },
        },
      }}
      {...props}
    />
  )
}

export default NPINumberField
