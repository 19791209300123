import React, { useState, useEffect, useCallback } from 'react'
import { Paper, Button, Grid, Checkbox, FormControlLabel, } from '@mui/material'
import { isEqual } from 'lodash'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import GenericChipField from 'components/UserFields/GenericChipField'
import GenericMultiSelectCheckboxField from 'components/UserFields/GenericMultiSelectCheckboxField'
import GenericMultiLineTextField from 'components/UserFields/GenericMultiLineTextField'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import {
  getStatesOptions,
  getAcademicOptions,
  getAreaOptions,
  getYesNoOptions,
  getPracticeTypeOptions,
  AREA_ICONS,
} from 'components/UserFields/helpers'
import { getChangedFields, clearFieldError, validateFields } from '../helpers'

const PreferencesSection = ({ formData, onSave }) => {
  const [localFormData, setLocalFormData] = useState({
    ...formData,
    relocationPreference: formData.relocationPreference ? 'yes' : 'no',
  })
  const [initialData, setInitialData] = useState(formData)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})
  const [hasLocationPreference, setHasLocationPreference] = useState(
    localFormData.preferredStates?.length > 0,
  )
  const stateOptions = getStatesOptions()

  useEffect(() => {
    setLocalFormData({
      ...formData,
      relocationPreference: formData.relocationPreference ? 'yes' : 'no',
    })
    setInitialData(formData)
    setHasChanges(false)
    setHasLocationPreference(formData.preferredStates?.length > 0)
  }, [formData])

  const handleInputChange = event => {
    const { name, value } = event.target
    clearFieldError(name, errors, setErrors)
    setLocalFormData(prev => ({ ...prev, [name]: value }))
    setHasChanges(!isEqual(localFormData, initialData))
  }

  const handleLocationPreferenceChange = event => {
    setHasLocationPreference(event.target.checked)
    if (!event.target.checked) {
      setLocalFormData(prev => ({ ...prev, preferredStates: [] }))
    }
  }

  const validateAndSetErrors = useCallback(() => {
    const requiredFields = { relocationPreference: true, aboutJob: true }
    const newErrors = validateFields(localFormData, requiredFields)
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }, [localFormData])

  const handleSave = () => {
    if (!validateAndSetErrors()) return
    const changedFields = getChangedFields(localFormData, initialData)

    if (Object.keys(changedFields).length > 0) {
      onSave({
        ...changedFields,
        id: formData.id,
        relocationPreference: localFormData.relocationPreference === 'yes',
      })
      setInitialData(localFormData)
      setHasChanges(false)
    }
  }

  return (
    <Paper elevation={3} sx={{ padding: '25px', borderRadius: '12px' }}>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={hasLocationPreference} onChange={handleLocationPreferenceChange} />
            }
            label='I have a location preference'
          />
          {hasLocationPreference && (
            <GenericMultipleSelect
              label='Preferred States'
              name='preferredStates'
              value={localFormData.preferredStates || []}
              handleChange={e => handleInputChange({ target: { name: 'preferredStates', value: e.target.value } })}
              options={stateOptions}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <GenericRadioGroup
            value={localFormData.relocationPreference}
            label='Willing to Relocate'
            name='relocationPreference'
            handleChange={handleInputChange}
            options={getYesNoOptions()}
            required
            error={errors.relocationPreference}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericChipField
            value={localFormData.preferredAreaTypes}
            label='Area Type Preference'
            name='preferredAreaTypes'
            icons={AREA_ICONS}
            options={getAreaOptions()}
            handleChange={e => handleInputChange({ target: { name: 'preferredAreaTypes', value: e.target.value } })}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericMultiSelectCheckboxField
            label='Practice Type Preference'
            name='preferredPracticeTypes'
            value={localFormData.preferredPracticeTypes || []}
            options={getPracticeTypeOptions()}
            handleChange={e => handleInputChange({ target: { name: 'preferredPracticeTypes', value: e.target.value } })}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericRadioGroup
            value={localFormData.academicPreference}
            label='Preferred Academic Setting'
            name='academicPreference'
            handleChange={handleInputChange}
            options={getAcademicOptions()}
          />
        </Grid>

        <Grid item xs={12}>
          <GenericMultiLineTextField
            name='aboutJob'
            label='Describe your ideal job'
            placeholder='Describe the ideal setting, work environment, and team that would allow you to thrive professionally.'
            value={localFormData.aboutJob}
            required
            error={errors.aboutJob}
            helperText='This field is required'
            handleChange={handleInputChange}
          />
        </Grid>
      </Grid>

      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges || Object.keys(errors).length > 0}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Paper>
  )
}

export default PreferencesSection
