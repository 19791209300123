import React, { useEffect, useState } from 'react'
import { Button, Box, Typography, Avatar, Grid } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { isEqual } from 'lodash'
import PhoneNumberField from 'components/UserFields/PhoneNumberField'
import GenericCheckboxField from 'components/UserFields/GenericCheckboxField'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import GenericDatePicker from 'components/UserFields/GenericDatePicker'
import GenericTextField from 'components/UserFields/GenericTextField'
import { getAvailabilityOptions } from 'components/UserFields/helpers'
import { getPhysicianProfileImage } from 'src/pages/Physicians/helpers'
import { UploadPhotoButton } from 'pages/Users/Profile/Buttons'
import { SEARCH_STATUS_HELPER_TEXT } from 'data/fieldHelperText'
const BasicInformationStep = ({ formData, setFormData, onNext, user, candidate, step }) => {
  const imageURL = getPhysicianProfileImage(candidate.profileImage)
  const [errors, setErrors] = useState({})
  const [initialFormData, setInitialFormData] = useState({}) // Track initial form data for change detection

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        phone: candidate.phone,
        showPhone: candidate.showPhone,
        showEmail: candidate.showEmail,
        email: candidate.email,
        searchStatus: candidate.searchStatus,
        availabilityDate: candidate.availabilityDate,
        id: user.candidateId,
      }
      setFormData(initialData)
      setInitialFormData(initialData) // Store initial data
    }
  }, [user, candidate, setFormData])

  const handleInputChange = event => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
    if (errors[name]) {
      setErrors({ ...errors, [name]: false })
    }
  }

  const handleValidation = () => {
    const newErrors = {}
    if (!formData.searchStatus) newErrors.searchStatus = true
    if (!formData.availabilityDate) newErrors.availabilityDate = true
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      // Only submit if changes exist
      const dataToSubmit = Object.fromEntries(
        Object.entries({
          availabilityDate: formData.availabilityDate,
          searchStatus: formData.searchStatus,
          id: candidate.id,
          setupSeen: true,
          phone: formData.phone,
          showPhone: formData.showPhone,
          showEmail: formData.showEmail,
        }).filter(([, value]) => value !== null && value !== undefined && value !== ''),
      )
      const submitForm = !isEqual(formData, initialFormData)
      onNext({ dataToSubmit: dataToSubmit, step: step, submitForm: submitForm })
    }
  }

  return (
    <Box maxWidth={600} margin='auto'>
      <Box display='flex' alignItems='center' flexDirection='column' mb={2}>
        {imageURL ? (
          <Avatar src={imageURL} sx={{ width: 100, height: 100 }} />
        ) : (
          <AccountCircleIcon color='primary' sx={{ fontSize: 100 }} />
        )}
        <Typography variant='h6' mt={1}>
          Welcome, {candidate.firstName}!
        </Typography>
        <UploadPhotoButton user={{ ...candidate, type: 'candidate' }} />
      </Box>

      <Typography variant='body2' color='textSecondary' mb={2} textAlign='center'>
        MyStethi is focused on building a network of serious candidates and employers. Please
        provide accurate information to help us match you with the right opportunities.
      </Typography>

      <Grid container spacing={1}>
        {/* Contact Information Section */}
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Typography variant='subtitle2' fontWeight='medium' color='textSecondary' mb={2}>
              Contact Information
            </Typography>

            <Grid container spacing={1} alignItems='center'>
              <Grid item xs={12} md={6}>
                <GenericTextField
                  value={formData.email}
                  disabled
                  label='Email'
                  name='email'
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneNumberField
                  value={formData.phone}
                  handleChange={handleInputChange}
                  label='Phone Number'
                  name='phone'
                  fullWidth
                  error={errors.phone}
                  helperText={errors.phone && 'Phone number is required'}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <GenericCheckboxField
                  name='showEmail'
                  label='Display Email'
                  value={formData.showEmail}
                  handleChange={handleInputChange}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {formData.phone && (
                  <GenericCheckboxField
                    name='showPhone'
                    label='Display Phone Number'
                    value={formData.showPhone}
                    handleChange={handleInputChange}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Job Search Details Section */}
        <Grid item xs={12}>
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <GenericRadioGroup
                  value={formData.searchStatus}
                  name='searchStatus'
                  label='Job Search Stage'
                  toolTipText={SEARCH_STATUS_HELPER_TEXT}
                  handleChange={handleInputChange}
                  options={getAvailabilityOptions()}
                  row
                  required={true}
                  error={errors.searchStatus}
                  helperText={errors.searchStatus && 'Please select your job search stage'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GenericDatePicker
                  value={formData.availabilityDate}
                  handleChange={handleInputChange}
                  label='Availability Date'
                  name='availabilityDate'
                  required={true}
                  error={errors.availabilityDate}
                  helperText={errors.availabilityDate && 'Availability date is required'}
                  sx={{ maxWidth: '250px' }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Next Button */}
      <Box textAlign='center' mt={3} mb={7}>
        <Button variant='contained' color='primary' onClick={handleSubmit}>
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default BasicInformationStep
