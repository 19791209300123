import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Box, Button, Grid, TextField } from '@mui/material'
import { useSpecialties } from 'data/layout/actions'
import { useIdentity } from 'pages/Identity/Login'
import { getAnnualSalaryRange, getHourlySalaryRange } from 'data/constants'
import GenericSectionHeader from '../GenericComponents/GenericSectionHeader'
import GenericCollapsableSection from '../GenericComponents/GenericCollapsableSection'
import { TextEditor } from 'components/TextEditor/TextEditor'
import JobTextField from './JobTextField'
import JobMultiSelectTextField from './JobMultiSelectTextField'
import JobChipField from './JobChipField'
import JobRadioGroup from './JobRadioGroup'
import {
  ACADEMIC_ICONS,
  AREA_ICONS,
  academicOptions,
  areaOptions,
  practiceTypeOptions,
  salaryTypeOptions,
  shiftTypeOptions,
  stateOptions,
  visaOptions,
  yesNoOptions,
} from './constants'
import { fetchCreateJob, fetchUpdateJob } from 'src/redux/job'
import { PRACTICE_TYPE_HELPER_TEXT, ACADEMIC_TYPE_HELPER_TEXT } from 'data/fieldHelperText'

const JobInformationSection = ({
  jobToEdit,
  isEdit,
  onResponse,
  onClose,
  handleSuccessfulSavedJob,
  isOpenByDefault,
  setJobAlertData,
  handleSuccessfulJobEdit,
  title,
}) => {
  const { specialties, subspecialties, candidateTypes } = useSpecialties()
  const dispatch = useDispatch()
  const { employerUserId, isEmployerAdmin, employerId } = useIdentity()
  const [values, setValues] = useState({
    about: null,
    aboutRaw: null,
    title: null,
    candidateTypeId: null,
    city: null,
    state: null,
    practiceType: null,
    salaryType: null,
    specialtyIds: [],
    subspecialtyIds: [],
    visaIds: null,
    loanForgiveness: null,
    pslfCompatible: null,
    area: null,
    academicType: null,
    shiftType: null,
    salaryMin: null,
    salaryMax: null,
    salary: null,
  })

  const [formSpecialties, setFormSpecialties] = useState([])
  const [formSubspecialties, setFormSubspecialties] = useState([])
  const [salaryRange, setSalaryRange] = useState([])
  const [showError, setShowError] = useState(false)
  const [didInitialize, setDidInitialize] = useState(false)

  const candidateTypeOptions = candidateTypes.map(({ id, name }) => ({ id, name }))
  useEffect(() => {
    if (jobToEdit && !didInitialize && isEdit) {
      const visaId = visaOptions.find(option => option.name === jobToEdit.visas)?.id || null

      setValues({
        id: jobToEdit.id ?? null,
        about: jobToEdit.about ?? null,
        aboutRaw: jobToEdit.aboutRaw ?? null,
        title: jobToEdit.title ?? null,
        candidateTypeId: jobToEdit.candidateTypeId ?? null,
        city: jobToEdit.city ?? null,
        state: jobToEdit.state ?? null,
        practiceType: jobToEdit.practiceType ?? null,
        salaryType: jobToEdit.salaryType ?? null,
        specialtyIds: jobToEdit.specialties?.map(specialty => specialty.id) ?? [],
        subspecialtyIds: jobToEdit.subspecialties?.map(subspecialty => subspecialty.id) ?? [],
        visaIds: visaId,
        loanForgiveness: jobToEdit.loanForgiveness ?? null,
        pslfCompatible: jobToEdit.pslfCompatible ?? null,
        area: jobToEdit.area ?? null,
        academicType: jobToEdit.academicType ?? null,
        shiftType: jobToEdit.shiftType ?? null,
        salaryMin: jobToEdit.salaryMin ?? null,
        salaryMax: jobToEdit.salaryMax ?? null,
        salary: jobToEdit.salary ?? null,
      })
      setDidInitialize(true)
    }
  }, [jobToEdit, didInitialize])

  // Memoize dependencies to avoid re-renders
  const memoizedSpecialties = useMemo(() => specialties, [specialties])
  const memoizedSubspecialties = useMemo(() => subspecialties, [subspecialties])
  const memoizedCandidateTypes = useMemo(() => candidateTypes, [candidateTypes])

  // Update formSpecialties based on candidateTypeId
  useEffect(() => {
    if (values.candidateTypeId && memoizedSpecialties.length && memoizedCandidateTypes.length) {
      const relevantSpecialties = memoizedSpecialties.filter(s =>
        memoizedCandidateTypes
          .find(ct => ct.id === values.candidateTypeId)
          ?.specialtyIds.includes(s.id),
      )
      if (relevantSpecialties.length !== formSpecialties.length) {
        // Avoid redundant sets
        setFormSpecialties(relevantSpecialties)
      }
    }
  }, [values.candidateTypeId, memoizedSpecialties, memoizedCandidateTypes, formSpecialties.length])

  // Update formSubspecialties based on selected specialties only when they change
  useEffect(() => {
    if (values.specialtyIds.length && memoizedSubspecialties.length && formSpecialties.length) {
      const updatedSubspecialties = memoizedSubspecialties.filter(sub =>
        values.specialtyIds.some(id =>
          formSpecialties.some(sp => sp.id === id && sp.subspecialtyIds.includes(sub.id)),
        ),
      )
      if (updatedSubspecialties.length !== formSubspecialties.length) {
        // Avoid redundant sets
        setFormSubspecialties(updatedSubspecialties)
      }
    }
  }, [values.specialtyIds, memoizedSubspecialties, formSpecialties, formSubspecialties.length])

  // Update salary range based on salaryType
  useEffect(() => {
    const getSalaryRange =
      values.salaryType === 'Annual' ? getAnnualSalaryRange : getHourlySalaryRange
    setSalaryRange(getSalaryRange().values.map(({ value, label }) => ({ id: value, name: label })))
  }, [values.salaryType])

  const handleFieldChange = (name, val) => {
    setValues(prev => ({ ...prev, [name]: val }))
  }

  const handleEditorChange = ({ about, aboutRaw }) => {
    setValues(prev => ({ ...prev, about, aboutRaw }))
  }

  const areRequiredFieldsFilled = useCallback(
    () =>
      values.title && values.candidateTypeId && values.practiceType && values.city && values.state,
    [values],
  )
  const handleSuccess = () => {
    handleSuccessfulSavedJob()
  }

  const handleSubmit = useCallback(() => {
    if (!areRequiredFieldsFilled()) {
      setShowError(true)
      return
    }

    const jobData = {
      ...values,
      employerUserId,
      visaIds: values.visaIds ? [Number(values.visaIds)] : [],
      areaType: values.area,
      shiftType: values.candidateTypeId === 2 ? values.shiftType : null,
    }

    if (isEdit) {
      dispatch(fetchUpdateJob({ values: jobData, onSuccess: handleSuccessfulJobEdit }))
    } else {
      dispatch(fetchCreateJob({ values: jobData, employerId: employerId }))
    }
    handleSuccess()
  }, [values, employerUserId, onResponse, isEmployerAdmin, areRequiredFieldsFilled])

  return (
    <>
      <GenericCollapsableSection title={title} isOpenByDefault={isOpenByDefault}>
        {/* Basics Section */}
        <GenericSectionHeader title='Basics'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JobTextField
                required
                label='Job Title'
                name='title'
                showError={showError}
                value={values.title}
                setState={handleFieldChange}
                setJobAlertData={setJobAlertData}
              />
            </Grid>
            <Grid item xs={12}>
              <JobTextField
                required
                label='Profession'
                name='candidateTypeId'
                showError={showError}
                setJobAlertData={setJobAlertData}
                value={values.candidateTypeId}
                options={candidateTypeOptions}
                setState={handleFieldChange}
              />
            </Grid>
            {formSpecialties.length > 0 && (
              <Grid item xs={6}>
                <JobMultiSelectTextField
                  label='Specialties'
                  name='specialtyIds'
                  value={values.specialtyIds || []}
                  options={formSpecialties}
                  setState={handleFieldChange}
                  setJobAlertData={setJobAlertData}
                />
              </Grid>
            )}
            {formSubspecialties.length > 0 && (
              <Grid item xs={6}>
                <JobMultiSelectTextField
                  label='Subspecialties'
                  name='subspecialtyIds'
                  value={values.subspecialtyIds || []}
                  options={formSubspecialties}
                  setState={handleFieldChange}
                  setJobAlertData={setJobAlertData}
                />
              </Grid>
            )}
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6}>
                <JobTextField
                  required
                  label='Practice Type'
                  name='practiceType'
                  showError={showError}
                  value={values.practiceType}
                  options={practiceTypeOptions}
                  setState={handleFieldChange}
                  toolTipText={PRACTICE_TYPE_HELPER_TEXT}
                />
              </Grid>
              {values.candidateTypeId === 2 && (
                <Grid item xs={6}>
                  <JobTextField
                    label='Shift Type'
                    name='shiftType'
                    showError={showError}
                    value={values.shiftType}
                    options={shiftTypeOptions}
                    setState={handleFieldChange}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <JobTextField
                label='External Link'
                name='link'
                value={values.link}
                setState={handleFieldChange}
              />
            </Grid>
          </Grid>
        </GenericSectionHeader>

        {/* Location Section */}
        <GenericSectionHeader title='Location'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <JobTextField
                label='City'
                name='city'
                value={values.city}
                setState={handleFieldChange}
                showError={showError}
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <JobTextField
                label='State'
                name='state'
                value={values.state}
                options={stateOptions}
                setState={handleFieldChange}
                showError={showError}
                setJobAlertData={setJobAlertData}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <JobChipField
                label='Area'
                name='area'
                options={areaOptions}
                icons={AREA_ICONS}
                value={values.area}
                setState={handleFieldChange}
              />
            </Grid>
          </Grid>
        </GenericSectionHeader>

        {/* Benefits Section */}
        <GenericSectionHeader title='Benefits'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <JobChipField
                label='Academic'
                name='academicType'
                options={academicOptions}
                icons={ACADEMIC_ICONS}
                value={values.academicType}
                setState={handleFieldChange}
                tooltipText={ACADEMIC_TYPE_HELPER_TEXT}
              />
            </Grid>
            <Grid item xs={12}>
              <JobRadioGroup
                label='Visa Sponsorship'
                name='visaIds'
                setState={handleFieldChange}
                value={values.visaIds}
                options={visaOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <JobRadioGroup
                label='Loan Forgiveness'
                name='loanForgiveness'
                setState={handleFieldChange}
                value={values.loanForgiveness}
                options={yesNoOptions}
              />
            </Grid>
            <Grid item xs={6}>
              <JobRadioGroup
                label='PSLF Compatible'
                name='pslfCompatible'
                setState={handleFieldChange}
                value={values.pslfCompatible}
                options={yesNoOptions}
              />
            </Grid>
          </Grid>
        </GenericSectionHeader>

        {/* Compensation Section */}
        <GenericSectionHeader title='Compensation'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <JobRadioGroup
                label='Salary Type'
                name='salaryType'
                setState={handleFieldChange}
                value={values.salaryType}
                options={salaryTypeOptions}
              />
            </Grid>
            {values.salaryType && (
              <>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={6}>
                    <JobTextField
                      label='Min Salary'
                      name='salaryMin'
                      value={values.salaryMin}
                      options={salaryRange}
                      setState={handleFieldChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JobTextField
                      label='Max Salary'
                      name='salaryMax'
                      value={values.salaryMax}
                      options={salaryRange}
                      setState={handleFieldChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <NumberFormat
                    label='Salary (Optional)'
                    value={values.salary || ''}
                    onValueChange={({ value }) => handleFieldChange('salary', value)}
                    thousandSeparator
                    prefix='$'
                    customInput={TextField}
                    fullWidth
                    size='small'
                  />
                </Grid>
              </>
            )}
          </Grid>
        </GenericSectionHeader>
        {/* Additional Content */}
        <Grid item xs={12}>
          <TextEditor
            initialValue={values.about || ''}
            onChange={handleEditorChange}
            placeholder='Enter Job Description'
          />
        </Grid>
        <Box display='flex' justifyContent='flex-end' sx={{ marginTop: '10px' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            sx={{ marginLeft: '8px', opacity: !areRequiredFieldsFilled() ? 0.6 : 1 }}
          >
            Save
          </Button>
        </Box>
      </GenericCollapsableSection>
    </>
  )
}

export default JobInformationSection
