import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, Grid, IconButton, Checkbox, FormControlLabel } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { isEqual } from 'lodash'
import { TextEditor } from 'components/TextEditor/TextEditor'
import GenericTextField from 'components/UserFields/GenericTextField'
import GenericDatePicker from 'components/UserFields/GenericDatePicker'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import {
  getVisaOptions,
  getLanguageOptions,
  getProficiencyOptions,
} from 'components/UserFields/helpers'
import {
  getChangedFields,
  clearFieldError,
  validateFields,
  handleInputChangeHelper,
} from '../helpers'

const BackgroundInformationSection = ({ formData, onSave }) => {
  const [localFormData, setLocalFormData] = useState(formData)
  const [initialData, setInitialData] = useState(formData)
  const [hasChanges, setHasChanges] = useState(false)
  const [errors, setErrors] = useState({})
  const [requiresVisa, setRequiresVisa] = useState(localFormData.visaId !== null)
  const [languages, setLanguages] = useState(
    localFormData.candidateLanguages || [{ language: '', proficiency: '' }],
  )

  useEffect(() => {
    setLocalFormData(formData)
    setInitialData(formData)
    setHasChanges(false)
    setLanguages(
      formData.candidateLanguages?.map(lang => ({
        language: lang.language,
        proficiency: lang.proficiency,
      })),
    )
  }, [formData])

  useEffect(() => {
    const localFormDataCopy = { ...localFormData }
    const initialDataCopy = { ...initialData }

    delete localFormDataCopy.aboutRaw
    delete initialDataCopy.aboutRaw

    setHasChanges(!isEqual(localFormDataCopy, initialDataCopy))
  }, [localFormData, initialData])

  const handleInputChange = event => {
    handleInputChangeHelper(event, setLocalFormData, name =>
      clearFieldError(name, errors, setErrors),
    )
  }

  const handleVisaCheckboxChange = event => {
    const checked = event.target.checked
    setRequiresVisa(checked)
    setLocalFormData(prev => ({ ...prev, visaId: checked ? prev.visaId : null }))
    setHasChanges(true)
  }

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguages = languages.map((lang, i) =>
      i === index ? { ...lang, [field]: value } : lang,
    )
    setLanguages(updatedLanguages)
    setLocalFormData(prev => ({ ...prev, languages: updatedLanguages }))
  }

  const handleAddLanguage = () => {
    const updatedLanguages = [...languages, { language: '', proficiency: '' }]
    setLanguages(updatedLanguages)
    setLocalFormData(prev => ({ ...prev, languages: updatedLanguages }))
  }

  const handleRemoveLanguage = index => {
    const updatedLanguages = languages.filter((_, i) => i !== index)
    setLanguages(updatedLanguages)
    setLocalFormData(prev => ({ ...prev, languages: updatedLanguages }))
  }

  const handleEditorChange = content => {
    clearFieldError('about', errors, setErrors)
    setLocalFormData(prev => ({ ...prev, about: content.about, aboutRaw: content.aboutRaw }))
  }

  const validateAndSetErrors = useCallback(() => {
    const requiredFields = { about: true, visaId: requiresVisa }
    const newErrors = validateFields(localFormData, requiredFields)
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }, [localFormData, requiresVisa])

  const handleSave = () => {
    if (!validateAndSetErrors()) return

    const changedFields = getChangedFields(localFormData, initialData)
    if (Object.keys(changedFields).length > 0) {
      onSave({ ...changedFields, id: formData.id })
      setInitialData(localFormData)
      setHasChanges(false)
    }
  }

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <GenericTextField
              name='residency'
              label='Residency'
              value={localFormData.residency}
              handleChange={handleInputChange}
              error={errors.residency}
              helperText={errors.residency}
            />
          </Grid>
          <Grid item xs={6}>
            <GenericDatePicker
              name='residencyEnd'
              label='Residency End Date'
              value={localFormData.residencyEnd}
              handleChange={handleInputChange}
              error={errors.residencyEnd}
              helperText={errors.residencyEnd}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={requiresVisa}
                onChange={handleVisaCheckboxChange}
                color='primary'
              />
            }
            label='I will require a Visa'
          />
          {requiresVisa && (
            <GenericSelectField
              value={localFormData.visaId}
              label='Visa Type'
              name='visaId'
              handleChange={handleInputChange}
              options={getVisaOptions()}
              error={errors.visaId}
              helperText={errors.visaId}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          {languages.map((language, index) => (
            <Grid
              container
              spacing={2}
              alignItems='center'
              key={index}
              sx={{ mb: 3 }} // Add bottom margin for space between rows
            >
              <Grid item xs={5}>
                <GenericSelectField
                  options={getLanguageOptions()}
                  value={language.language}
                  label='Language'
                  handleChange={e => handleLanguageChange(index, 'language', e.target.value)}
                />
              </Grid>
              <Grid item xs={5}>
                <GenericSelectField
                  options={getProficiencyOptions()}
                  value={language.proficiency}
                  label='Proficiency'
                  handleChange={e => handleLanguageChange(index, 'proficiency', e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveLanguage(index)} color='error'>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button onClick={handleAddLanguage} variant='outlined' sx={{ mt: 1 }}>
            Add a Language
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextEditor
            initialValue={localFormData.about}
            placeholder='Highlight your professional journey and what motivates you in medicine.'
            onChange={handleEditorChange}
            error={errors.about}
          />
        </Grid>
      </Grid>

      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges || Object.keys(errors).length > 0}
        sx={{ mt: 3 }}
      >
        Save
      </Button>
    </Box>
  )
}

export default BackgroundInformationSection
