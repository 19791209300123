import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Autocomplete, Chip, useTheme } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Check } from '@mui/icons-material'

const SubspecialtyField = ({
  name,
  label,
  value = [],
  specialtyIds = [],
  handleChange,
  disabled,
  ...props
}) => {
  const theme = useTheme()
  const { specialties = [], subspecialties = [] } = useSelector(state => state.supportingData)

  const specialtyIdList = specialtyIds.map(specialty => specialty.id)

  const subspecialtyOptions = useMemo(() => {
    if (specialtyIdList.length === 0) return []

    return subspecialties.filter(sub =>
      specialtyIdList.some(id => {
        const specialty = specialties.find(specialty => specialty.id === id)
        return specialty?.subspecialtyIds?.includes(sub.id)
      }),
    )
  }, [specialtyIdList, specialties, subspecialties])

  if (subspecialtyOptions.length === 0) return null // Hide if no options

  const handleMultiSelectChange = (event, newValue) => {
    handleChange({
      target: { name, value: newValue },
    })
  }

  return (
    <Autocomplete
      multiple
      id={name}
      options={subspecialtyOptions}
      disabled={disabled}
      popupIcon={disabled ? null : <ArrowDropDownIcon />}
      getOptionLabel={option => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={handleMultiSelectChange}
      value={Array.isArray(value) ? value : []}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            label={option.name}
            {...getTagProps({ index })}
            sx={{
              '& .MuiChip-label': {
                color: theme.palette.primary.dark,
              },
              '&.MuiChip-root.Mui-disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
              },
            }}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {selected && <Check sx={{ marginRight: 1, color: 'green' }} />}
          {option.name}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: theme.palette.primary.dark,
            },
            '& fieldset': disabled && { border: 'none' },
          }}
          label={label}
        />
      )}
      {...props}
    />
  )
}

export default SubspecialtyField
