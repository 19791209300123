import React from 'react'
import { checkEmpty, imageAddress } from 'data/helpers'
import PhysicianPlaceholderImage from 'static/images/profile-image-placeholder.jpg'
import { getLocation } from '../Jobs/helpers'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LanguageIcon from '@mui/icons-material/Language'
import FlagIcon from '@mui/icons-material/Flag'
import SchoolIcon from '@mui/icons-material/School'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import BadgeIcon from '@mui/icons-material/Badge'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle'
import CircleIcon from '@mui/icons-material/Circle'
import WorkIcon from '@mui/icons-material/Work'
import { splitCamelCaseToString } from 'data/helpers'
import { YEARS_EXPERIENCE_ENUM } from 'data/enums'
export const getSpecialty = (specialty, subspecialty) => {
  return subspecialty ? `${specialty}: ${subspecialty}` : specialty
}

export const getPhysicianProfileImage = profileImage => {
  const { key, fileName } = checkEmpty(profileImage) ? {} : profileImage
  return !checkEmpty(key) ? imageAddress(key, fileName) : PhysicianPlaceholderImage
}

export const getResidency = (residency, residencyEnd) => {
  let residencyCombo = 'Residency: '
  if (residency) residencyCombo += residency
  if (residencyEnd) residencyCombo += `<br/>Complete on: ${new Date(residencyEnd).toDateString()}`
  return residencyCombo
}

export const getBoardingEligibility = boardEligibility => {
  return boardEligibility ? splitCamelCaseToString(boardEligibility) : ''
}

export const getAvailabilityStatus = ({ searchStatus, availabilityDate }) => {
  let result = searchStatus ? `${splitCamelCaseToString(searchStatus)}` : ''
  if (searchStatus && availabilityDate) result += ' | '
  if (availabilityDate) result += `Available on: ${availabilityDate}`
  return result
}

export const getSearchStatusColor = searchStatus => {
  switch (searchStatus) {
    case 'ActivelyLooking':
      return 'green'
    case 'PassivelyLooking':
      return 'orange'
    case 'NotLooking':
      return 'red'
    default:
      return 'black'
  }
}

export const getPhysicianListItems = physician => {
  const fields = []

  if (Array.isArray(physician.subspecialties) && physician.subspecialties.length > 0) {
    physician.subspecialty = physician.subspecialties[0].name
  }

  fields.push(
    // Background Information Section
    {
      text: `Current Location: ${getLocation(physician.city, physician.state)}`,
      icon: <LocationOnIcon />,
      show: !!getLocation(physician.city, physician.state),
      section: 'Background Information',
    },
    {
      text: (
        <div
          dangerouslySetInnerHTML={{
            __html: getResidency(physician.residency, physician.residencyEnd),
          }}
        />
      ),
      icon: <SchoolIcon />,
      show: !!physician.residency,
      section: 'Background Information',
    },
    {
      text: physician.visas?.length
        ? `Visa Types: ${physician.visas.map(visa => visa.name).join(', ')}`
        : 'No Visa Required',
      icon: <FlagIcon />,
      show: !!physician.visas?.length,
      section: 'Background Information',
    },
    {
      text: `Languages: ${
        Array.isArray(physician.candidateLanguages)
          ? physician.candidateLanguages
              .map(lang => `${lang.language} (${lang.proficiency})`)
              .join(', ')
          : ''
      }`,
      icon: <LanguageIcon />,
      show: Array.isArray(physician.candidateLanguages) && physician.candidateLanguages.length > 0,
      section: 'Background Information',
    },

    // Professional Details Section
    {
      text: `NPI: ${physician.npiNumber}`,
      icon: <BadgeIcon />,
      show: !!physician.npiNumber,
      section: 'Professional Details',
    },
    {
      text: getBoardingEligibility(physician.boardEligibility),
      icon: <ThumbUpIcon />,
      show: physician.boardEligibility !== null,
      section: 'Professional Details',
    },
    {
      text: `Licensed States: ${
        Array.isArray(physician.licensedStates) ? physician.licensedStates.join(', ') : ''
      }`,
      icon: <PersonPinCircleIcon />,
      show: Array.isArray(physician.licensedStates) && physician.licensedStates.length > 0,
      section: 'Professional Details',
    },
    {
      text: getAvailabilityStatus({
        searchStatus: physician.searchStatus,
        availabilityDate:
          physician.availabilityDate && new Date(physician.availabilityDate).toDateString(),
      }),
      icon: (
        <CircleIcon size='small' sx={{ color: getSearchStatusColor(physician.searchStatus) }} />
      ),
      show: !!physician.availabilityDate,
      section: 'Professional Details',
    },
    {
      text: getSpecialty(
        physician.specialties && physician.specialties[0]?.name,
        physician.subspecialty,
      ),
      icon: <MedicalServicesIcon />,
      show: physician.specialties && physician.specialties.length > 0,
      section: 'Professional Details',
    },
    {
      text: `Years of Experience: ${YEARS_EXPERIENCE_ENUM[physician.yearsExperience] || 'Unknown'}`,
      icon: <WorkIcon />,
      show: physician.yearsExperience != null,
      section: 'Professional Details',
    },
    {
      text: `Preferred States: ${
        Array.isArray(physician.preferredStates) ? physician.preferredStates.join(', ') : ''
      }`,
      icon: <AddLocationAltIcon />,
      show: Array.isArray(physician.preferredStates) && physician.preferredStates.length > 0,
      section: 'Preferences',
    },
    {
      text: `Academic Setting Preference: ${splitCamelCaseToString(physician.academicPreference)}`,
      icon: <SchoolIcon />,
      show: !!physician.academicPreference,
      section: 'Preferences',
    },
    {
      text: `Preferred Practice Types: ${
        Array.isArray(physician.preferredPracticeTypes)
          ? physician.preferredPracticeTypes.join(', ')
          : ''
      }`,
      icon: <MedicalServicesIcon />,
      show:
        Array.isArray(physician.preferredPracticeTypes) &&
        physician.preferredPracticeTypes.length > 0,
      section: 'Preferences',
    },
    {
      text: `Preferred Area Types: ${
        Array.isArray(physician.preferredAreaTypes) ? physician.preferredAreaTypes.join(', ') : ''
      }`,
      icon: <WhereToVoteIcon />,
      show: Array.isArray(physician.preferredAreaTypes) && physician.preferredAreaTypes.length > 0,
      section: 'Preferences',
    },
  )

  return fields
}
