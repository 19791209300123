import React from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  useTheme,
  IconButton,
  InputLabel,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CloseIcon from '@mui/icons-material/Close'

const GenericSelectField = ({
  options,
  value,
  label,
  name,
  disabled = false,
  handleChange,
  helperText,
  required = false,
  error = false,
  errorText = '',
  ...props
}) => {
  const theme = useTheme()

  return (
    <FormControl sx={{ width: '100%' }} required={required} error={error}>
      {/* Adjusted Input Label positioning */}
      <InputLabel
        htmlFor={name}
        sx={{
          color: theme.palette.primary.main,
          transform: 'translate(14px, 12px) scale(1)', // Adjust label position when no value
          '&.Mui-focused': {
            color: theme.palette.primary.main,
          },
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)', // Position when shrinked
          },
        }}
        shrink={Boolean(value)} // Shrinks label when a value is selected
      >
        {label}
      </InputLabel>

      <Select
        id={name}
        name={name}
        required={required}
        IconComponent={() =>
          disabled ? null : value !== '' && value !== null ? (
            <IconButton onClick={() => handleChange({ target: { name, value: null } })}>
              <CloseIcon />
            </IconButton>
          ) : (
            <ArrowDropDownIcon />
          )
        }
        value={value || ''}
        onChange={event => handleChange(event)}
        disabled={disabled}
        size='small'
        label={label}
        sx={{
          color: 'black', // Selected text color
          '& .MuiSelect-select': {
            color: 'black', // Ensures selected text remains black when opened
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
          },
          '& fieldset': disabled && { border: 'none' },
        }}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id} sx={{ color: 'black' }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{error ? errorText : helperText}</FormHelperText>
    </FormControl>
  )
}

export default GenericSelectField
