import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, Grid, Typography } from '@mui/material'
import { isEqual } from 'lodash'
import GenericSelectField from 'components/UserFields/GenericSelectField'
import SpecialtyField from 'components/UserFields/SpecialtyField'
import SubspecialtyField from 'components/UserFields/SubspecialtyField'
import NPINumberField from 'components/UserFields/NPINumberField'
import GenericMultipleSelect from 'components/UserFields/GenericMultipleSelect'
import GenericRadioGroup from 'components/UserFields/GenericRadioGroup'
import { getBoardingEligibilityOptions, getStatesOptions } from 'components/UserFields/helpers'
import { professionFields } from 'components/UserFields/professionDefinitions.js'
import { getCandidateTypeOptions, getYearsExperienceOptions } from 'components/UserFields/helpers'
import ProfessionalDetails from 'static/images/professional-details.png'

const ProfessionalDetailsStep = ({
  formData,
  setFormData,
  onNext,
  onBack,
  candidate,
  user,
  step,
}) => {
  const [errors, setErrors] = useState({})
  const [candidateType, setCandidateType] = useState({})
  const { candidateTypes } = useSelector(state => state.supportingData)
  const [initialFormData, setInitialFormData] = useState({})

  useEffect(() => {
    if (formData.candidateTypeId) {
      const candidateTypeData = candidateTypes.find(x => x.id === formData.candidateTypeId)
      setCandidateType(professionFields.find(x => x.name === candidateTypeData.name))
    }
  }, [formData.candidateTypeId])

  useEffect(() => {
    if (!candidate.loading) {
      const initialData = {
        specialtyIds: candidate.specialties,
        subspecialtyIds: candidate.subspecialties,
        npiNumber: candidate.npiNumber,
        boardEligibility: candidate.boardEligibility,
        licensedStates: candidate.licensedStates,
        candidateTypeId: candidate.candidateType.id,
        yearsExperience: candidate.yearsExperience,
        id: candidate.candidateId,
      }
      setFormData(initialData)
      setInitialFormData(initialData)
    }
  }, [candidate, candidateTypes, setFormData])

  const handleInputChange = event => {
    const { name, value } = event.target

    if (name === 'candidateTypeId') {
      // Clear specialties and subspecialties when candidate type changes
      setFormData({
        ...formData,
        [name]: value,
        specialtyIds: [],
        subspecialtyIds: [],
      })
    } else if (name === 'specialtyIds') {
      // Filter subspecialties that are related to still-selected specialties
      const remainingSubspecialties = formData.subspecialtyIds.filter(
        subspecialty => value.includes(subspecialty.relatedSpecialtyId), // Keep subspecialties for selected specialties
      )

      setFormData({
        ...formData,
        [name]: value,
        subspecialtyIds: remainingSubspecialties,
      })

      // Remove "required" error for specialties if a selection exists
      if (errors.specialtyIds && value.length > 0) {
        setErrors(prevErrors => {
          const { ...rest } = prevErrors
          return rest
        })
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  const handleValidation = () => {
    const newErrors = {}
    if (!formData.candidateTypeId) newErrors.candidateTypeId = 'Candidate type is required'
    if (candidateType?.specialtyIds?.required && !formData.specialtyIds?.length) {
      newErrors.specialtyIds = 'Specialty is required'
    }
    if (candidateType?.npiNumber?.required && !formData.npiNumber) {
      newErrors.npiNumber = 'NPI Number is required'
    }
    if (candidateType?.boardEligibility?.required && !formData.boardEligibility) {
      newErrors.boardEligibility = 'Board Eligibility is required'
    }
    if (!formData.yearsExperience) newErrors.yearsExperience = 'Years of Experience is required'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      const dataToSubmit = {
        id: user.candidateId,
        candidateTypeId: formData.candidateTypeId,
        specialtyIds: formData.specialtyIds.map(specialty => parseInt(specialty.id, 10)),
        subspecialtyIds: formData.subspecialtyIds.map(subspecialty =>
          parseInt(subspecialty.id, 10),
        ),
        npiNumber: formData.npiNumber,
        boardEligibility: formData.boardEligibility,
        licensedStates: formData.licensedStates,
        yearsExperience: formData.yearsExperience,
      }
      const submitForm = !isEqual(formData, initialFormData)
      onNext({ dataToSubmit, step, submitForm })
    }
  }

  return (
    <Box padding={2} maxWidth={600} margin='auto'>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            component='img'
            src={ProfessionalDetails}
            alt='Professional Details'
            sx={{
              width: '40%',
              height: 'auto',
              marginBottom: 1,
              display: 'block',
              mx: 'auto',
            }}
          />
          <Box border={1} borderColor='grey.300' borderRadius={1} padding={1.5} mt={1}>
            <Box display='flex' alignItems='center' mb={2}>
              <Typography variant='subtitle2' fontWeight='medium' color='textSecondary' mr={1}>
                Profession Details
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {/* Candidate Type Field */}
              <Grid item xs={12}>
                <GenericSelectField
                  value={formData.candidateTypeId}
                  label='Profession'
                  name='candidateTypeId'
                  handleChange={handleInputChange}
                  options={getCandidateTypeOptions({ candidateTypes })}
                />
              </Grid>
              {/* Specialty Field */}
              {candidateType?.specialtyIds?.show !== false && (
                <Grid item xs={12}>
                  <SpecialtyField
                    value={formData.specialtyIds || []}
                    handleChange={handleInputChange}
                    name='specialtyIds'
                    label='Specialty'
                    errors={errors}
                    setErrors={setErrors}
                    required={candidateType?.specialtyIds?.required}
                    candidateTypeId={formData.candidateTypeId}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <SubspecialtyField
                  value={formData.subspecialtyIds || []}
                  handleChange={handleInputChange}
                  name='subspecialtyIds'
                  specialtyIds={formData.specialtyIds}
                  label='Subspecialty'
                />
              </Grid>
              {/* NPI Number Field */}
              {candidateType?.npiNumber?.show !== false && (
                <Grid item xs={12} md={6}>
                  <NPINumberField
                    handleChange={handleInputChange}
                    value={formData.npiNumber}
                    label='NPI Number'
                    name='npiNumber'
                    setErrors={setErrors}
                    errors={errors}
                    required={candidateType?.npiNumber?.required}
                  />
                </Grid>
              )}
              {/* Board Eligibility Field */}
              {candidateType?.boardEligibility?.show !== false && (
                <Grid item xs={12} md={6}>
                  <GenericSelectField
                    value={formData.boardEligibility}
                    label='Board Eligibility'
                    name='boardEligibility'
                    handleChange={handleInputChange}
                    options={getBoardingEligibilityOptions()}
                    required={candidateType?.boardEligibility?.required}
                    error={errors.boardEligibility}
                    helperText={errors.boardEligibility}
                  />
                </Grid>
              )}
              {/* Licensed States Field */}
              {candidateType?.licensedStates?.show !== false && (
                <Grid item xs={12}>
                  <GenericMultipleSelect
                    value={formData.licensedStates}
                    label='Licensed States'
                    name='licensedStates'
                    handleChange={handleInputChange}
                    options={getStatesOptions()}
                    required={candidateType?.licensedStates?.required}
                  />
                </Grid>
              )}
              {/* Years of Experience Required */}
              <Grid item xs={12}>
                <GenericRadioGroup
                  value={formData.yearsExperience}
                  label='Years of Experience Required'
                  name='yearsExperience'
                  handleChange={handleInputChange}
                  options={getYearsExperienceOptions()}
                  required={true}
                  error={errors.yearsExperience}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Navigation Buttons */}
      <Grid container spacing={2} justifyContent='center' mt={3} mb={7}>
        <Grid item>
          <Button
            onClick={onBack}
            variant='outlined'
            color='primary'
            fullWidth
            sx={{ minWidth: 120 }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit}
            variant='contained'
            color='primary'
            fullWidth
            sx={{ minWidth: 120 }}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProfessionalDetailsStep
