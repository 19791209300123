export const professionFields = [
  {
    name: 'Physician',
    specialtyIds: { show: true, required: true },
    npiNumber: { show: true, required: true },
    boardEligibility: { show: true, required: true },
    licensedStates: { show: true, required: true },
    residency: { show: true, required: true },
    residencyEnd: { show: true, required: true },
  },
  {
    name: 'Advanced Practice Provider (NP/PA)',
    specialtyIds: { show: true, required: true },
    npiNumber: { show: true, required: false },
    boardEligibility: { show: true, required: false },
    licensedStates: { show: true, required: false },
    residency: { show: false, required: false },
    residencyEnd: { show: false, required: false },
  },
  {
    name: 'CRNA (Nurse Anesthetist)',
    specialtyIds: { show: false, required: false },
    npiNumber: { show: true, required: false },
    boardEligibility: { show: true, required: false },
    licensedStates: { show: true, required: false },
    residency: { show: false, required: false },
    residencyEnd: { show: false, required: false },
  },
  {
    name: 'Nurse',
    specialtyIds: { show: true, required: true },
    npiNumber: { show: true, required: true },
    boardEligibility: { show: true, required: true },
    residency: { show: false, required: false },
    residencyEnd: { show: false, required: false },
  },
  {
    name: 'Nurse Midwife',
    specialtyIds: { show: false, required: false },
    npiNumber: { show: true, required: true },
    boardEligibility: { show: true, required: true },
    residency: { show: false, required: false },
    residencyEnd: { show: false, required: false },
  },
]
