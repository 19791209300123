import React from 'react'
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  useTheme,
  Tooltip,
  IconButton,
  Box,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DOMPurify from 'dompurify'

const GenericMultiSelectCheckboxField = ({
  label,
  name,
  value = [],
  options,
  handleChange,
  required = false,
  error = false,
  toolTipText = '', // Optional tooltip text
}) => {
  const theme = useTheme()

  const handleCheckboxChange = event => {
    const selectedValue = event.target.name
    const newValue = value.includes(selectedValue)
      ? value.filter(item => item !== selectedValue) // Deselect if already selected
      : [...value, selectedValue] // Add new selection

    handleChange({ target: { name, value: newValue } })
  }

  return (
    <FormControl component='fieldset' required={required} error={error} fullWidth>
      <FormLabel
        component='legend'
        sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}
      >
        {label}
        {toolTipText && (
          <Tooltip
            title={
              <Box
                sx={{ whiteSpace: 'pre-line' }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(toolTipText),
                }}
              />
            }
            placement='top'
            arrow
          >
            <IconButton size='small' sx={{ ml: 1, color: theme.palette.primary.main }}>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        )}
      </FormLabel>

      <FormGroup row>
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            control={
              <Checkbox
                checked={value.includes(option.id)}
                onChange={handleCheckboxChange}
                name={option.id}
              />
            }
            label={option.name}
          />
        ))}
      </FormGroup>

      {error && required && <FormHelperText>Please select at least one option</FormHelperText>}
    </FormControl>
  )
}

export default GenericMultiSelectCheckboxField
