import React from 'react'
import { TextField, FormControl, FormLabel, FormHelperText, useTheme } from '@mui/material'

const GenericMultiLineTextField = ({
  name,
  value,
  label,
  disabled,
  placeholder,
  handleChange,
  required = false,
  error = false,
  helperText = '',
}) => {
  const theme = useTheme()

  return (
    <FormControl
      variant='outlined'
      fullWidth={true}
      required={required}
      error={error}
      sx={{ width: '100%' }}
    >
      <FormLabel id={`${name}-form`} sx={{ color: theme.palette.primary.main }}>
        {label}
      </FormLabel>

      <TextField
        id={name}
        name={name}
        placeholder={placeholder}
        multiline
        disabled={disabled}
        rows={4}
        variant='outlined'
        value={value || ''}
        onChange={event => handleChange(event)}
        required={required}
        error={error}
        sx={{
          width: '100%',
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: theme.palette.primary.dark,
          },
          '& fieldset': disabled && { border: 'none' },
        }}
      />

      {/* Conditionally show helper text or error message */}
      {error && helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  )
}

export default GenericMultiLineTextField
