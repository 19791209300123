import React from 'react'
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material'
import { styled } from '@mui/system'
import PersonIcon from '@mui/icons-material/Person'
import WorkIcon from '@mui/icons-material/Work'
import SchoolIcon from '@mui/icons-material/School'
import FavoriteIcon from '@mui/icons-material/Favorite'

const CustomStepConnector = styled(StepConnector)(() => ({
  [`& .MuiStepConnector-line`]: {
    borderWidth: '5px',
    borderRadius: '25px',
    marginLeft: '12px',
    marginRight: '12px',
  },
}))

// Map step index to an icon component
const stepIcons = {
  0: PersonIcon,
  1: WorkIcon,
  2: SchoolIcon,
  3: FavoriteIcon,
}

// Custom icon component to handle color changes
const StepIcon = ({ icon, active, completed }) => {
  const IconComponent = stepIcons[icon - 1]
  return (
    <IconComponent
      fontSize='medium'
      sx={{
        color: completed
          ? 'primary.main' // Color for completed steps
          : active
            ? 'secondary.main' // Color for the active/current step
            : 'grey.400', // Color for upcoming/inactive steps
        transition: 'color 0.3s ease',
      }}
    />
  )
}

const CustomStepLabel = styled(StepLabel)(() => ({
  [`& .MuiStepLabel-label`]: {
    transition: 'color 0.3s, font-size 0.3s',
    marginTop: '4px', // Reduce margin above the label
  },
}))

const OnboardingStepper = ({ steps, activeStep }) => (
  <Stepper
    activeStep={activeStep}
    alternativeLabel
    connector={<CustomStepConnector />}
    sx={{
      backgroundColor: 'transparent', // Remove default background
      padding: '10px 0',
    }}
  >
    {steps.map((label, index) => (
      <Step key={index}>
        <CustomStepLabel
          StepIconComponent={() => (
            <StepIcon
              icon={index + 1} // Pass step index to icon
              active={activeStep === index}
              completed={activeStep > index}
            />
          )}
          sx={{
            '& .MuiStepLabel-label': {
              color:
                activeStep === index || activeStep > index
                  ? theme => theme.palette.primary.main
                  : theme => theme.palette.text.disabled,
              fontWeight: activeStep === index ? 'bold' : 'normal',
              fontSize: activeStep === index ? '0.9rem' : '0.8rem',
            },
          }}
        >
          {label}
        </CustomStepLabel>
      </Step>
    ))}
  </Stepper>
)

export default OnboardingStepper
