import React from 'react'
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  IconButton,
  Tooltip,
  useTheme,
  Box,
} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import DOMPurify from 'dompurify'

const GenericRadioGroup = ({
  value,
  label,
  name,
  handleChange,
  options,
  disabled,
  required,
  error,
  toolTipText = '', // Add toolTipText prop
}) => {
  const theme = useTheme()

  const handleRadioChange = event => {
    handleChange(event)
  }

  return (
    <FormControl
      variant='outlined'
      required={required}
      error={error}
      sx={{
        padding: '14px',
      }}
      disabled={disabled}
    >
      {label && (
        <FormLabel
          id={`${name}-label`}
          component='legend'
          sx={{ color: theme.palette.primary.main, display: 'flex', alignItems: 'center' }}
        >
          {label}

          {/* Tooltip icon for additional information */}
          {toolTipText && (
            <Tooltip
              title={
                <Box
                  sx={{ whiteSpace: 'pre-line' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      toolTipText.replace(/\n/g, '<br/>'), // Convert line breaks to <br/> tags
                    ),
                  }}
                />
              }
              placement='top'
              arrow
            >
              <IconButton size='small' sx={{ ml: 1, color: theme.palette.primary.main }}>
                <InfoIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          )}
        </FormLabel>
      )}

      <RadioGroup
        row
        aria-labelledby={`${name}-label`}
        name={name}
        value={value || ''}
        onChange={handleRadioChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio checked={value === option.id} />}
            label={option.name}
          />
        ))}
      </RadioGroup>

      {error && required && <FormHelperText>Please select an option</FormHelperText>}
    </FormControl>
  )
}

export default GenericRadioGroup
