import React from 'react'
import { useDispatch } from 'react-redux'
import { Box, Avatar, IconButton, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CloseIcon from '@mui/icons-material/Close'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { UploadPhotoButton } from 'pages/Users/Profile/Buttons'
import { hostAddress } from 'data/helpers'
import { fetchUpdateCandidate } from 'src/redux/candidate'
import ResumeUpload from 'components/UserFields/ResumeUpload'
import { getPhysicianProfileImage } from 'src/pages/Physicians/helpers'

export const FileSection = ({ formData, candidate }) => {
  const dispatch = useDispatch()
  const imageURL = getPhysicianProfileImage(candidate.profileImage)

  const handleResumeClick = () => {
    fetch(
      hostAddress(
        `/api/storage/resume?key=${formData.resume.key}&fileName=${formData.resume.fileName}`,
      ),
      { method: 'GET' },
    ).then(response => response.blob().then(blob => window.open(URL.createObjectURL(blob))))
  }

  const handleRemoveResume = () => {
    dispatch(fetchUpdateCandidate({ id: candidate.id, removeResume: true }))
  }

  const handleResumeSave = event => {
    const file = event.target.files?.[0]
    if (file) {
      dispatch(fetchUpdateCandidate({ id: candidate.id, resumeFile: file }, 'Uploaded Resume'))
    }
  }

  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      alignItems='flex-start'
      sx={{ mb: 3, gap: 4, minHeight: '250px' }} // Ensure consistent container height
    >
      {/* Profile Image Column */}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between' // Pushes content to top and bottom
        alignItems='center'
        sx={{ width: '50%', height: '100%', minHeight: '250px' }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          {imageURL ? (
            <Avatar src={imageURL} sx={{ width: 130, height: 130, mb: 2 }} />
          ) : (
            <AccountCircleIcon color='primary' sx={{ fontSize: 130, mb: 2 }} />
          )}
        </Box>
        <UploadPhotoButton buttonId='profile-upload' user={{ ...candidate, type: 'candidate' }} />
      </Box>

      {/* Resume Column */}
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between' // Pushes content to top and bottom
        alignItems='center'
        sx={{ width: '50%', height: '100%', minHeight: '250px' }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          {/* Use Avatar with InsertDriveFileIcon to match size */}
          <Avatar sx={{ width: 130, height: 130, mb: 2, bgcolor: 'primary.main' }}>
            <InsertDriveFileIcon sx={{ fontSize: 60, color: '#fff' }} />
          </Avatar>
          {formData.resume ? (
            <Box display='flex' alignItems='center'>
              <Typography
                component='a'
                onClick={handleResumeClick}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  color: 'primary.main.dark',
                  fontSize: 16,
                  mr: 1,
                }}
              >
                {formData.resume.fileName}
              </Typography>
              <IconButton onClick={handleRemoveResume} color='error' size='small'>
                <CloseIcon fontSize='small' />
              </IconButton>
            </Box>
          ) : (
            <Typography variant='body2' color='textSecondary' textAlign='center'>
              No resume uploaded.
            </Typography>
          )}
        </Box>
        <ResumeUpload
          id='resume-upload'
          value={formData.resume}
          handleChange={handleResumeSave}
          label={formData.resume ? 'Update Resume' : 'Upload Resume'}
          name='resume'
          loading={candidate?.loading}
        />
      </Box>
    </Box>
  )
}

export default FileSection
