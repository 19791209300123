import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import PasswordField from 'components/UserFields/PasswordField'
import { fetchUpdatePassword } from 'src/redux/candidate'

const PasswordSection = () => {
  const dispatch = useDispatch()
  const { id } = useSelector(state => state.candidate)
  const [passwordData, setPasswordData] = useState({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  })
  const [errors, setErrors] = useState({})
  const [hasChanges, setHasChanges] = useState(false)

  const handleInputChange = event => {
    const { name, value } = event.target
    setPasswordData(prev => ({ ...prev, [name]: value }))
    setHasChanges(true)
  }

  const handleSave = () => {
    if (Object.keys(errors).length > 0) return

    dispatch(
      fetchUpdatePassword({
        currentPassword: passwordData.currentPassword,
        password: passwordData.password,
        confirmPassword: passwordData.confirmPassword,
        id: id,
      }),
    )

    setPasswordData({
      currentPassword: '',
      password: '',
      confirmPassword: '',
    })
    setHasChanges(false)
  }

  const allFieldsFilled =
    passwordData.currentPassword && passwordData.password && passwordData.confirmPassword

  return (
    <>
      <PasswordField
        handleChange={handleInputChange}
        values={passwordData}
        errors={errors}
        setErrors={setErrors}
        disabled={false}
      />

      <Button
        variant='contained'
        color='primary'
        onClick={handleSave}
        disabled={!hasChanges || Object.keys(errors).length > 0 || !allFieldsFilled}
        sx={{ mt: 3 }}
      >
        Save New Password
      </Button>
    </>
  )
}

export default PasswordSection
